import React, { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "../components/Auth/ProtectedRoute";
import Loading from "../components/Loading/loading"

const SignIn = lazy(() => import('../components/Auth/SignIn'));
const SignUp = lazy(() => import('../components/Auth/SignUp'));
const Page1 = lazy(() => import('../Pages/page1'));
const Page2 = lazy(() => import('../Pages/Page2'));
const Home = lazy(() => import('../Pages/home/Home'));
const NotFound = lazy(() => import('../components/NotFound/NotFound'));
const SetPassword = lazy(() => import('../components/Auth/SetPassword'));
const MailNOtification = lazy(() => import("../components/Auth/MailNotification"));
const ResetPassword = lazy(() => import('../components/Auth/ResetPassword'));
const Bonds = lazy(() => import('../Pages/issuance-data/bonds/bonds'));
const BondIndividual = lazy(() => import('../Pages/issuance-data/bonds/individual/BondIndividual'));
const Issuers = lazy(() => import('../Pages/issuance-data/issuers/Issuers'));
const IssuerBond = lazy(() => import("../Pages/issuance-data/issuers/individual/IssuerBond"));
const Framework = lazy(() => import("../Pages/issuance-data/frameworks/Frameworks"));
const FrameworkDetails = lazy(() => import("../Pages/issuance-data/frameworks/individual/Framework"));
const Exchanges = lazy(() => import("../Pages/issuance-data/exchanges/Exchanges"));
const ExchangeBond = lazy(() => import("../Pages/issuance-data/exchanges/individual/BondsExchange"));
const Currency = lazy(() => import("../Pages/issuance-data/currency/Currency"));
const BondsCurrency = lazy(() => import("../Pages/issuance-data/currency/individual/BondsCurrency"));
const UploadData = lazy(() => import("../Pages/issuance-data/bonds/uploadData/UploadData"));


export const routes = [
    {
        path: "/",
        element: <Navigate to="/home" replace />,
    },
    {
        path: '/signin',
        element: <Suspense fallback={<Loading />}><SignIn /></Suspense>
    },
    {
        path: '/signup',
        element: <Suspense fallback={<Loading />}><SignUp /></Suspense>
    }, {
        path: '/mail',
        element: <Suspense fallback={<Loading />}><MailNOtification /></Suspense>
    },
    {
        path: '/set-password',
        element: <Suspense fallback={<Loading />}><SetPassword /></Suspense>

    },
    {
        path: '/reset-password',
        element: <Suspense fallback={<Loading />}><ResetPassword /></Suspense>
    }, {
        path: '/home',
        element: <ProtectedRoute />,    //  element: isAuthenticated() ? <DashboardLayout /> : <Navigate to="/signup" replace />,
        children: [
            { index: true, element: <Suspense fallback={<Loading />}><Home /></Suspense> },
            { path: 'bonds', element: <Suspense fallback={<Loading />}><Bonds /></Suspense> },
            { path: 'bond/:id', element: <Suspense fallback={<Loading />}><BondIndividual /></Suspense> },
            { path: "upload", element: <Suspense fallback={<Loading />}><UploadData /></Suspense> },
            { path: "issuer", element: <Suspense fallback={<Loading />}> <Issuers /></Suspense> },
            { path: "issuer/:id", element: <Suspense fallback={<Loading />}> <IssuerBond /></Suspense> },
            { path: 'framework', element: <Suspense fallback={<Loading />} ><Framework /> </Suspense> },
            { path: 'framework/:id', element: <Suspense fallback={<Loading />} ><FrameworkDetails /> </Suspense> },
            { path: "exchanges", element: <Suspense fallback={<Loading />}><Exchanges /></Suspense> },
            { path: "exchanges/:id", element: <Suspense fallback={<Loading />}><ExchangeBond /></Suspense> },
            { path: "currency", element: <Suspense fallback={<Loading />}><Currency /></Suspense> },
            { path: "currency/:id", element: <Suspense fallback={<Loading />}><BondsCurrency /></Suspense> },
            { path: 'page2', element: <Suspense fallback={<Loading />}><Page2 /></Suspense> },
            { path: 'page1', element: <Suspense fallback={<Loading />}><Page1 /></Suspense> }
        ]
    },
    {
        path: "/loading",
        element: <Loading />,
    },

    {
        path: "*", element: <Suspense fallback={<Loading />}><NotFound /></Suspense>
    }
];