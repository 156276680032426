import axios from "axios";


const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
});


api.interceptors.request.use(config => {
    const publicRoutes = ["/auth/signin/", "/auth/signup/", "/auth/verify_reset_token/", "/auth/set_password/", "/auth/forgot_password/", '/auth/reset_password/'];
    if (!publicRoutes.includes(config.url)) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
}, (error) => {
    console.log("api", error);

    return Promise.reject(error);
});

export const signIn = (credentials) => api.post('/auth/signin/', credentials);
export const signUp = (data) => api.post('/auth/signup/', data);
export const verifyToken = (token) => api.post('/auth/verify_reset_token/', token);
export const newPassword = (password) => api.post('/auth/set_password/', password);
export const forgetPassword = (mail) => api.post('/auth/forgot_password/', mail);
export const resetPassword = (data) => api.post('/auth/reset_password/', data);

export const signOut = (refreshToken) => api.post('/auth/signout/', refreshToken);


//user info
export const userInfo = () => api.get('/auth/user_info/');

// bond page

export const bondListView = () => api.get(`/bonds/bonds_list/${localStorage.getItem("userId")}/`)
export const bondsDetailsView = (id) => api.get(`/bonds/bonds_view/${localStorage.getItem("userId")}/${id}/`)


export const BondDownloadTemplate = () => api.get('/bonds/download_template_file/');


