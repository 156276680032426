import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Styles from "./DashboardLayout.module.css";

const DashbordLayout = () => {
    return (
        <div className={Styles.dashboard}>
            <Sidebar />
            <div>
                <Header />

                <Outlet />
            </div>
        </div>
    )
}

export default DashbordLayout;