import { signOut } from "../../api/apiService";
import { toast } from "react-toastify";

export const getAccessToken = () => localStorage.getItem("token");

export const isAuthenticated = () => {
    const token = getAccessToken();
    return !!token; //true or false
}

export const logout = async (navigate) => {  // Pass navigate as a parameter

    const refreshToken = localStorage.getItem("refreshToken");

    try {


        if (refreshToken) {
            const responce = await signOut({ refresh_token: refreshToken });
            toast.success("Logout Successfully");
            // Only remove relevant authentication tokens
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            navigate("/signin");
        }


    } catch (error) {
        console.error("Logout error:", error);
        toast.error("Failed to log out. Please try again.");
    }

    // Redirect properly
    // Using React Router's navigation instead of window.location.href
};