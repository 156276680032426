import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import router from './routes/router';
import './App.css';

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default App;
