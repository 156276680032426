import Styles from "./loading.module.css";

const Loading = () => {
    return (
        <div className={Styles["mid-point"]}>
            <div className={Styles.loader}>
                <div className={`${Styles.box} ${Styles.first}`}></div>
                <div className={`${Styles.box} ${Styles.second}`}></div>
                <div className={`${Styles.box} ${Styles.third}`}></div>
                <div className={`${Styles.box} ${Styles.fourth}`}></div>
            </div>
        </div>
    )
}

export default Loading;