import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Home from "../../assets/images/Layout/Header/icon-home.svg";
import Styles from "./Header.module.css";
import { logout } from "../Auth/auth";


import User from "../../assets/images/Layout/Header/icon-user.svg";
import Seting from "../../assets/images/Layout/Header/icon-settings.svg";
import Notification from "../../assets/images/Layout/Header/icon-notification.svg";
import Profile from "../../assets/images/Layout/Header/profile.png";
import CompanyProfileIcon from "../../assets/images/Layout/Header/company-profile.svg";
import ManageUserIcon from "../../assets/images/Layout/Header/manage-user.svg";
import ManageRoleIcon from "../../assets/images/Layout/Header/managing-role.svg";
import LogoutIcon from "../../assets/images/Layout/Header/logout.svg";

const Header = () => {

    const location = useLocation();

    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout(navigate);
    };

    const [dropdown, setDropdown] = useState(null);

    // Toggle dropdown visibility
    const toggleDropdown = (menu) => {
        setDropdown(dropdown === menu ? null : menu);
    };
    return (
        <div className={Styles.bg}>
            <header>
                <h1 className={Styles.path}><img src={Home} alt="" srcSet="" />
                    <span>{location.pathname.replace(/^\/(\w)/, (match, p1) => `/${p1.toUpperCase()}`)}</span>
                </h1>

                <nav>
                    <ul className={Styles.icon}>
                        <li className={Styles.icon} onClick={() => toggleDropdown("notification")}> <img className={dropdown === "notification" ? Styles.active : ""} src={Notification} alt="" srcSet="" />
                            {dropdown === "notification" && (
                                <div className={Styles.dropdown}>
                                    <div className={Styles.notificationItem}>
                                        <div className={Styles.flex}><img className={Styles["notification-image"]} src={Profile} alt="" srcSet="" /> <p className={Styles["notification-text"]}>Lorem ipsum dolor sit amet Lorem ipsum.</p></div>
                                        <span className={Styles["notification-time"]}>2m</span>
                                    </div>
                                    <div className={Styles.notificationItem}>
                                        <div className={Styles.flex}><img className={Styles["notification-image"]} src={Profile} alt="" srcSet="" /> <p className={Styles["notification-text"]}>Lorem ipsum dolor sit amet Lorem ipsum.</p></div>
                                        <span className={Styles["notification-time"]}>2m</span>
                                    </div>

                                </div>
                            )}
                        </li>
                        <li className={Styles.icon} onClick={() => toggleDropdown("setting")}><img className={dropdown === "setting" ? Styles.active : ""} src={Seting} alt="" srcSet="" />
                            {dropdown === "setting" && (
                                <div className={Styles.dropdown}>
                                    <div className={Styles.setting}>
                                        <div className={Styles.flex}><img className={Styles["setting-image"]} src={CompanyProfileIcon} alt="" srcSet="" /> <p className={Styles["setting-text"]}>Company Profile</p></div>

                                    </div>
                                    <div className={Styles.setting}>
                                        <div className={Styles.flex}><img className={Styles["setting-image"]} src={ManageUserIcon} alt="" srcSet="" /> <p className={Styles["setting-text"]}>Manage Users</p></div>
                                    </div>
                                    <div className={Styles.setting}>
                                        <div className={Styles.flex}><img className={Styles["setting-image"]} src={ManageRoleIcon} alt="" srcSet="" /> <p className={Styles["setting-text"]}>Manage Roles</p></div>
                                    </div>

                                </div>
                            )}
                        </li>
                        <li className={Styles.icon} onClick={() => toggleDropdown("user")}><img className={dropdown === "user" ? Styles.active : ""} src={User} alt="" srcSet="" />
                            {dropdown === "user" && (
                                <div className={Styles.dropdown}>
                                    <div className={Styles.logout}>
                                        <div onClick={handleLogout} className={Styles.flex}><img className={Styles["setting-image"]} src={LogoutIcon} alt="" srcSet="" /> <p className={Styles["setting-text"]}>Logout</p></div>
                                    </div>
                                </div>
                            )}
                        </li>
                    </ul>

                </nav>
            </header>
        </div>
    )
}

export default Header;