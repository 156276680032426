import React from "react";
import { Navigate } from "react-router-dom";
import DashbordLayout from "../Layout/DashboardLayout";
import { isAuthenticated } from "./auth";

const ProtectedRoute = () => {

    const isAuth = isAuthenticated();

    return isAuth ? <DashbordLayout /> : <Navigate to="/signup" replace />;
}

export default ProtectedRoute;