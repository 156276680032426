import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Styles from "./Sidebar.module.css";
import SfiIcon from "../../assets/images/Auth/logo-icon.svg";
import MenuIcon from "../../assets/images/Layout/Sidebar/ios-menu.svg";
import HomeIcon from "../../assets/images/Layout/Sidebar/home.svg";
import Arrow from "../../assets/images/Layout/Sidebar/arrow.svg";
import IssuanceIcon from "../../assets/images/Layout/Sidebar/issuance-icon.svg";
import AreaIcon from "../../assets/images/Layout/Sidebar/area-icon.svg";
import UseCaseIcon from "../../assets/images/Layout/Sidebar/statistics-icon.svg";
import BondsIcon from "../../assets/images/Layout/Sidebar/bonds.svg";
import IssuersIcon from "../../assets/images/Layout/Sidebar/issuers.svg";
import FrameworksIcon from "../../assets/images/Layout/Sidebar/frameworks.svg";
import CurrencyIcon from "../../assets/images/Layout/Sidebar/currency.svg";
import ExchangesIcon from "../../assets/images/Layout/Sidebar/exchanges.svg";
import PortfoliosIcon from "../../assets/images/Layout/Sidebar/protfolios.svg";
import BenchmarkingIcon from "../../assets/images/Layout/Sidebar/benchmarking.svg";
import InstrumentsIcon from "../../assets/images/Layout/Sidebar/instruments.svg";



const Sidebar = () => {

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const location = useLocation();

    useEffect(() => {
        setIsSidebarCollapsed(window.innerWidth <= 768); // Ensures safe access to `window`
        const handleResize = () => setIsSidebarCollapsed(window.innerWidth <= 768);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    const toggleMenu = (menu) => {
        setActiveMenu(activeMenu === menu ? null : menu);
        setIsSidebarCollapsed(false);
    }

    const isActive = (path) => location.pathname === path;

    const menuItems = [
        {
            id: "home",
            label: "Home",
            icon: HomeIcon,
            path: "/home",
        },
        {
            id: "issuancedata",
            label: "Issuance Data",
            icon: IssuanceIcon,
            subMenu: [
                { label: "Bonds", path: "/home/bonds", icon: BondsIcon },
                { label: "Issuers", path: "/home/issuer", icon: IssuersIcon },
                { label: "Frameworks", path: "/home/framework", icon: FrameworksIcon },
                { label: "Currency", path: "/home/currency", icon: CurrencyIcon },
                { label: "Exchanges", path: "/home/exchanges", icon: ExchangesIcon },
            ]
        },
        {
            id: "impact-area",
            label: "Impact Area",
            icon: AreaIcon,
            subMenu: [{ label: "Impact Area", path: "page2", icon: InstrumentsIcon }]
        },
        {
            id: "usecase",
            label: "Use Cases",
            icon: UseCaseIcon,
            subMenu: [
                { label: "Portfolios", path: "/portfolios", icon: PortfoliosIcon },
                { label: "Benchmarking", path: "/benchmarking", icon: BenchmarkingIcon }
            ]
        }
    ];

    return (
        <div className={`${Styles.bg} ${isSidebarCollapsed ? Styles.collapsed : ""}`} id={Styles.sidebar}>
            <div>
                <div className={Styles.logo}>
                    <img className={Styles["logo-img"]} src={SfiIcon} alt="" srcSet="" />
                    {!isSidebarCollapsed && (
                        <>
                            <span className={Styles["logo-sfi"]}>SFI</span>
                            <span className={Styles["logo-data"]}>DATA</span>
                        </>

                    )}
                    <img className={Styles["menu-icon"]} src={MenuIcon} alt="" srcSet="" onClick={toggleSidebar} />
                </div>
            </div>

            <ul className="" style={{ overflow: "auto" }}>
                {menuItems.map((item) => (
                    <li className={Styles["menu-margin"]} key={item.id}>
                        {item.subMenu ? (
                            <button
                                className={`${Styles["dropdown-menu"]} ${activeMenu === item.id ? Styles.active : ""}`}
                                onClick={() => toggleMenu(item.id)}
                            >
                                <span>
                                    <img src={item.icon} alt="" />
                                    {!isSidebarCollapsed && <span className={Styles["menu-text"]}>{item.label}</span>}
                                </span>

                                {!isSidebarCollapsed && <img
                                    className={`${Styles.arrow} ${activeMenu === item.id ? Styles["arrow-rotate"] : ""}`}
                                    src={Arrow}
                                    alt=""
                                />}

                            </button>
                        ) : (
                            <Link to={item.path} className={`${Styles.menu} ${isActive(item.path) ? Styles.active : ""}`}>
                                <img src={item.icon} alt="" />
                                {!isSidebarCollapsed && <span className={Styles["menu-text"]}>{item.label}</span>}
                            </Link>
                        )}

                        {item.subMenu && (
                            <ul className={`${Styles["sub-menu"]} ${activeMenu === item.id ? Styles.show : ""}`}>
                                <div>
                                    {!isSidebarCollapsed && (
                                        item.subMenu.map((sub) => (
                                            <li className={Styles["sub-menu-margin"]} key={sub.path}>
                                                <Link
                                                    className={`${Styles["sub-menu-text"]} ${isActive(sub.path) ? Styles.active : ""}`}
                                                    to={sub.path}
                                                >
                                                    {sub.label}
                                                </Link>

                                                <Link className={`${Styles["sub-menu-icon"]} ${isActive(sub.path) ? Styles.active : ""}`} to={sub.path} >  <img src={sub.icon} alt={sub.label} /></Link>

                                            </li>
                                        ))
                                    )}
                                </div>
                            </ul>
                        )}
                    </li>
                ))}
            </ul>

        </div >
    )
}

export default Sidebar;